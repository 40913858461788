import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"

const API_PATH = "api/ScriptGeneration/"

export type ScriptGenerationResult = { script: string }

export class ScriptGenerationApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async generateScript(prompt: string): Promise<ScriptGenerationResult> {
    const response: ApiResponse<ScriptGenerationResult> = await this.api.apisauce.post(API_PATH, {
      prompt,
    })
    if (!response.ok || !response.data) {
      throw new ApiError("ScriptGenerationApi.generateScript", response)
    }
    return response.data
  }
}
